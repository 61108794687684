import { useCallback } from 'react';
import { usePublicCustomerJourney } from '../api/public/customer-journey';
import { SEGMENT } from '../constants/analytics';
import { useSegment } from './use-segment';

interface CustomerJourneyRedirect {
  redirectAccordingToCustomerJourney: () => void;
  isCustomerJourneyFetched: boolean;
  nextStepUrl: string | null | undefined;
}

const useCustomerJourneyRedirect = (gid: string): CustomerJourneyRedirect => {
  const { data: customerJourneyData, isFetched: isCustomerJourneyFetched } = usePublicCustomerJourney(gid);

  const { track } = useSegment();

  const redirectAccordingToCustomerJourney = useCallback((): void => {
    if (!customerJourneyData?.customer_journey.next_step_url) {
      return;
    }
    track(SEGMENT.events.fromDigitalProfileToCustomerPortalRedirected);
    window.open(customerJourneyData.customer_journey.next_step_url, '_self');
  }, [customerJourneyData, track]);

  return {
    redirectAccordingToCustomerJourney,
    isCustomerJourneyFetched,
    nextStepUrl: customerJourneyData?.customer_journey.next_step_url,
  };
};

export default useCustomerJourneyRedirect;
