import { FullStory, init } from '@fullstory/browser';

import { fullStoryIntegration } from '@sentry/fullstory';
import { extraErrorDataIntegration, debugIntegration, httpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { useEffect, useRef } from 'react';
import analytics from '../utils/analytics.util';
import { isErrorToSkip } from '../utils/error.util';
import type { Integrations } from '../types/public-config.type';
import type { Integration } from '@sentry/types';

const initAllIntegrations = (integrations: Integrations): void => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { full_story } = integrations;
  integrations.segment?.enabled && integrations.segment.key && analytics.init(integrations.segment.key);

  const integrationsArray: Integration[] = [httpClientIntegration(), debugIntegration(), extraErrorDataIntegration()];

  if (integrations.sentry?.dsn && integrations.sentry.environment) {
    full_story && init({ orgId: full_story?.org_id });
    full_story?.org_slug && integrationsArray.push(fullStoryIntegration(full_story?.org_slug, { client: FullStory }));

    Sentry.init({
      dsn: integrations.sentry.dsn,
      environment: integrations.sentry.environment,
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
      autoSessionTracking: false,
      denyUrls: [
        'http://localhost:5173',
        'http://localhost:3000',
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        /^moz-extension:\/\//i,
      ],
      integrations: [...integrationsArray],
      initialScope: {
        tags: { sender: 'browser' },
      },
      beforeSend: (event, hint) => {
        if (isErrorToSkip(hint.originalException)) {
          return null;
        }
        return event;
      },
    });
  }
};

const useAnalytics = (integrations?: Integrations): void => {
  const readyRef = useRef<boolean>();

  useEffect(() => {
    if (import.meta.env.PROD && integrations && !readyRef.current) {
      try {
        readyRef.current = true;
        initAllIntegrations(integrations);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to load analytics', error);
      }
    }
  }, [integrations]);
};

export default useAnalytics;
