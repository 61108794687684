import { useEffect, useState } from 'react';
import useCustomerJourneyRedirect from '../../hooks/use-customer-journey-redirect';
import { useCreateQuoteRequest, useLastQuoteRequest, useQuoteRequestsSubscription } from './index';
import type { QuoteRequest, QuoteType } from '../../types/quote.type';

interface InitQuoteRequestSubscriptionProps {
  gid: string;
  webSocketUrl?: string;
  shouldRefetchManually?: boolean;
  triggerRedirect?: boolean;
}

const useInitQuoteRequestSubscription = <T = QuoteType>({
  gid,
  webSocketUrl,
  shouldRefetchManually,
  triggerRedirect = false,
}: InitQuoteRequestSubscriptionProps): {
  quoteRequest: QuoteRequest<T> | null;
  isPending: boolean;
} => {
  const { isFinished, startSubscription } = useQuoteRequestsSubscription({ gid, webSocketUrl });
  const { mutateAsync: createRequest } = useCreateQuoteRequest(gid);
  const { redirectAccordingToCustomerJourney, isCustomerJourneyFetched } = useCustomerJourneyRedirect(gid);

  const [isPending, setIsPending] = useState<boolean>(true);

  const {
    data,
    isSuccess,
    refetch: refetchLastQuoteRequest,
    isPending: isLastQuoteRequestPending,
  } = useLastQuoteRequest<T>(gid);

  useEffect(() => {
    if (isFinished) {
      refetchLastQuoteRequest();
      setIsPending(false);
    }
  }, [isFinished, refetchLastQuoteRequest]);

  useEffect(() => {
    if (shouldRefetchManually) {
      refetchLastQuoteRequest();
    }
  }, [shouldRefetchManually, refetchLastQuoteRequest]);

  useEffect(() => {
    setIsPending(true);
    const call = async (): Promise<void> => {
      startSubscription();
      await createRequest({});
      setIsPending(false);
    };

    if (isSuccess && data && !data.quotes_request && isCustomerJourneyFetched) {
      call();
      triggerRedirect && redirectAccordingToCustomerJourney();
    } else if (isSuccess && !data?.quotes_request?.finished_at) {
      startSubscription();
    } else {
      setIsPending(false);
    }
  }, [
    data,
    isSuccess,
    gid,
    createRequest,
    startSubscription,
    isCustomerJourneyFetched,
    redirectAccordingToCustomerJourney,
    triggerRedirect,
  ]);

  return {
    quoteRequest: data?.quotes_request ?? null,
    isPending: isPending || isLastQuoteRequestPending,
  };
};

export default useInitQuoteRequestSubscription;
